import aos from 'aos';

if (typeof window !== 'undefined') {
  document.addEventListener('DOMContentLoaded', () => {
    // aos animations
    aos.init();
  });
}

// Define the function before it is used
const getLocaleFromUrl = () => {
  const url = window.location.href;
  const parsedUrl = new URL(url);
  const pathSegments = parsedUrl.pathname.split('/').filter(Boolean);
  return pathSegments[0]; // Returns the first segment (locale)
};

if (typeof window !== 'undefined') {
  document.addEventListener('DOMContentLoaded', function () {
    let timerExecuted = false; // Flag to ensure the timer runs only once

    // Function to update label text
    const updateLabel = () => {
      const checkboxLabel = document.querySelector('.chkboxclass');
      if (checkboxLabel) {
        checkboxLabel.style.marginRight = '1%';
        // Ensure only text updates, without clearing other elements
        const existingLinks = checkboxLabel.querySelectorAll('a');
        if (existingLinks.length > 0) return;

        // Find the text node specifically to update
        checkboxLabel.childNodes.forEach((node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            if (getLocaleFromUrl() === 'ar') {
              node.textContent = 'عند الضغط هنا، فإنك توافق على '; // Arabic text
            } else {
              node.textContent = 'By submitting, I consent to the '; // English text
            }
          }
        });

        // Create Terms and Conditions link
        const termsLink = document.createElement('a');
        termsLink.href = '/principle-of-use';
        termsLink.target = '_blank';
        if (getLocaleFromUrl() === 'ar') {
          termsLink.textContent = 'الشروط والأحكام'; // Arabic text for Terms and Conditions
        } else {
          termsLink.textContent = 'Terms and Conditions'; // English text
        }
        checkboxLabel.appendChild(termsLink);

        // Add separator text
        if (getLocaleFromUrl() === 'ar') {
          checkboxLabel.appendChild(document.createTextNode(' و ')); // Arabic separator
        } else {
          checkboxLabel.appendChild(document.createTextNode(' and ')); // English separator
        }

        // Create Privacy Notice link
        const privacyLink = document.createElement('a');
        privacyLink.href = '/privacy-policy';
        privacyLink.target = '_blank';
        if (getLocaleFromUrl() === 'ar') {
          privacyLink.textContent = 'سياسة الخصوصية.'; // Arabic text for Privacy Policy
        } else {
          privacyLink.textContent = 'Privacy Notice'; // English text
        }
        checkboxLabel.appendChild(privacyLink);

        // Add period (.) for English
        if (getLocaleFromUrl() !== 'ar') {
          checkboxLabel.appendChild(document.createTextNode('.'));
        }
      }
    };

    // Run the updateLabel function with a timer that runs only once
    const timer = setTimeout(() => {
      if (!timerExecuted) {
        updateLabel();
        timerExecuted = true; // Mark timer as executed
        clearTimeout(timer); // Disable timer after first run
      }
    }, 1000);

    // Alternatively, use a MutationObserver to monitor changes in the DOM and update accordingly
    const observer = new MutationObserver(updateLabel);
    observer.observe(document.body, { childList: true, subtree: true });
  });
}
