import React, { useState, useEffect } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import config from '../../temp/config';

import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import GalleryItem from '../GalleryItem';
import Spinner from '../Spinner';
import { object, func } from 'prop-types';

import { useGalleryFetch } from '../../hooks/useGalleryFetch';

const propTypes = {
  sitecoreContext: object.isRequired,
  t: func.isRequired,
};

const LatestGallery = (props) => {
  const { sitecoreContext, t } = props;

  // Function to get the current page ID
  const getCurrentPageId = () => {
    return sitecoreContext?.route?.itemId;
  };

  // Get total gallery count
  const fetchTotalGallery = async () => {
    const parentPageId = getCurrentPageId();
    const lang = sitecoreContext.language || 'en'; // Default to 'en' if language is not provided
    const skipGallery = ''; // Optional query parameter customization

    if (!parentPageId) {
      console.error('Parent Page ID is required.');
      return 0;
    }
    const filter = `ParentId eq ${parentPageId}`;
    const queryString = `sc_apikey=${config.sitecoreApiKey}&language=${lang}&$filter=${filter}`;
    let url = `${config.sitecoreApiHost}/sitecore/api/ssc/aggregate/content/Items?${queryString}`;
    const response = await fetch(url);

    if (!response.ok) {
      console.error(`Failed to fetch gallery. Status: ${response.status}`);
      return 0;
    }

    const data = await response.json();
    return data.value?.length || 0;
  };

  let totalGallery = useQuery('totalGallery', fetchTotalGallery, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  totalGallery = totalGallery.data;
  const parentPageId = getCurrentPageId();
  const { latestGallery, loading, setIsLoadingMore } = useGalleryFetch(
    config,
    sitecoreContext.language,
    parentPageId
  );
  return (
    <div className="container-bleed container-bleed__has-curved-bg">
      <div className="container">
        <div className="latest-news latest-news__page">
          <div className="latest-news__wrapper">
            {latestGallery.map((galleryItem) => (
              <GalleryItem
                data={galleryItem.FieldValues}
                key={galleryItem.Id}
                url={galleryItem.Path}
              />
            ))}
          </div>
          <div className="article__actions">
            <Link
              to={`/${sitecoreContext.language}/image-gallery/`}
              className="btn btn-secondary-article"
            >
              <span>{t('BacktoGallery')}</span>
            </Link>
          </div>
          {loading && <Spinner />}

          {/* {latestGallery.length < totalGallery && !loading && (
            <div className="latest-news__load-more">
              <button className="btn btn-primary" onClick={() => setIsLoadingMore(true)}>
                <span>{t('LoadMore')}</span>
              </button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

LatestGallery.propTypes = propTypes;

export default withTranslation()(withSitecoreContext()(LatestGallery));
