import { useEffect, useState } from 'react';

export const useGalleryFetch = (config, lang, parentPageId) => {
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);
  const [latestGallery, setLatestGallery] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const fetchGallery = async (skip) => {
    try {
      setLoading(true);
      let skipGallery = skip === 0 ? '' : `&$skip=${skip}`;
      const filter = `ParentId eq ${parentPageId}`;
      let url = `${config.sitecoreApiHost}/sitecore/api/ssc/aggregate/content/Items?sc_apikey=${config.sitecoreApiKey}&language=${lang}&$filter=${filter}&$orderby=FieldValues/Date desc&$expand=FieldValues${skipGallery}&$top=9`;
      const request = await fetch(url);
      let result = await request.json();
      setLatestGallery((prev) => (skip > 0 ? [...prev, ...result.value] : [...result.value]));
    } catch (error) {
      // setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchGallery(skip);
  }, []);

  // Load More
  useEffect(() => {
    if (!isLoadingMore) return;
    fetchGallery(skip + 9);
    setSkip(skip + 9);
    setIsLoadingMore(false);
  }, [isLoadingMore, skip]);

  return {
    latestGallery,
    loading,
    setIsLoadingMore,
  };
};
